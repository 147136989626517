/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import ArticleTeaser from "../components/entities/article/articleTeaser";
import { Link } from "gatsby";
import Container from "../components/container";
import { graphql } from "gatsby";
import SEO from "../components/seo";

const BlogIndex = (props) => {
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  const pageId = "blog-index-page-" + currentPage;
  const metaTitle = props.data.meta.metaTitle
    ? props.data.meta.metaTitle
    : "News";
  const metaDescription = props.data.meta.metaDescription
    ? props.data.meta.metaDescription
    : "";
  return (
    <Layout>
      <SEO title={metaTitle} lang="en" description={metaDescription}></SEO>
      <Container className="article-index-container">
        <div
          sx={{
            padding: "70px 0px 0px 0px",
            "&#blog-index-page-1": {
              "ul li": {
                "&:first-child": {
                  ".article-teaser-image-container": {
                    width: ["100%", "100%", "720px"],
                    marginRight: ["0px", "0px", "55px"],
                  },
                  ".article-teaser-text-container": {
                    width: ["100%", "100%", "calc(100% - 775px)"],
                  },
                },
              },
            },
          }}
          id={pageId}
        >
          <h1
            sx={{
              fontSize: "2.75rem",
              lineHeight: "3rem",
              margin: "0px 0px 40px 0px",
            }}
          >
            Company News
          </h1>
          <ul
            sx={{
              padding: "0px 0px 80px 0px",
            }}
          >
            {props.data.article.nodes.map((article, index) => (
              <li
                key={index}
                sx={{
                  marginBottom: "90px",
                  listStyle: "none",
                  "&:last-child": {
                    marginBottom: "0px",
                  },
                }}
              >
                <ArticleTeaser article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div
          className="pager"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "90px",
          }}
        >
          <div
            sx={{
              marginRight: ["40px", "130px"],
            }}
            className="pager-previous-container"
          >
            {!isFirst && (
              <Link
                sx={{
                  fontSize: "1.5rem",
                  textDecorationThickness: "1px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/news/` + prevPage}
                rel="prev"
                aria-label="Previous page of Company News"
              >
                <span
                  sx={{
                    display: ["none", "block"],
                  }}
                >
                  Previous
                </span>
                <span
                  sx={{
                    display: ["flex", "none"],
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    sx={{
                      width: "15px",
                    }}
                    src={props.data.blueArrowLeft.publicURL}
                  />
                </span>
              </Link>
            )}
          </div>

          <ul
            className="pager-container"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              margin: "0px",
              padding: "0px",
            }}
          >
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                sx={{
                  listStyle: "none",
                }}
              >
                <Link
                  className={`pagination-number-${i + 1}`}
                  to={`/news${i === 0 ? "" : "/" + (i + 1)}`}
                  activeClassName="pager-active"
                  aria-label={`Page ${i + 1} of Company News`}
                  sx={{
                    display: [
                      i + 1 == props.pageContext.currentPage ||
                      i + 1 == props.pageContext.currentPage + 1 ||
                      i + 1 == props.pageContext.currentPage + 2
                        ? "flex"
                        : "none",
                      i + 1 == props.pageContext.currentPage - 2 ||
                      i + 1 == props.pageContext.currentPage - 1 ||
                      i + 1 == props.pageContext.currentPage ||
                      i + 1 == props.pageContext.currentPage + 1 ||
                      i + 1 == props.pageContext.currentPage + 2
                        ? "flex"
                        : "none",
                    ],
                    width: "44px",
                    height: "44px",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                    fontSize: "1.5rem",
                    margin: "0px 5px",
                    "&.pager-active": {
                      borderRadius: "50%",
                      backgroundColor: "primary",
                      color: "white",
                    },
                  }}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
          </ul>
          <div
            sx={{
              marginLeft: ["40px", "130px"],
            }}
            className="pager-next-container"
          >
            {!isLast && (
              <Link
                sx={{
                  fontSize: "1.5rem",
                  textDecorationThickness: "1px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                to={`/news/` + nextPage}
                rel="next"
                aria-label="Next page of Company News"
              >
                <span
                  sx={{
                    display: ["none", "block"],
                  }}
                >
                  Next
                </span>
                <span
                  sx={{
                    display: ["flex", "none"],
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    sx={{
                      width: "15px",
                    }}
                    src={props.data.blueArrowRight.publicURL}
                  />
                </span>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogIndex;

export const articleIndexQuery = graphql`
  query blogIndexQuery($skip: Int!, $limit: Int!) {
    blueArrowRight: file(name: { eq: "Right-Blue-Caret" }) {
      publicURL
    }
    blueArrowLeft: file(name: { eq: "Left-Blue-Caret" }) {
      publicURL
    }
    meta: sanityPageDefinition(slug: { current: { eq: "news" } }) {
      metaDescription
      metaTitle
    }
    article: allSanityArticle(
      filter: {
        post_type: { eq: "blog_post" }
        _id: { regex: "/^(?!draft).*$/" }
        slug: { current: { ne: null } }
      }
      limit: $limit
      skip: $skip
      sort: { fields: [pinned, publish_date], order: [DESC, DESC] }
    ) {
      nodes {
        headline
        post_type
        short_title
        publish_date(formatString: "MMMM D, Y")
        _rawIntroText(resolveReferences: { maxDepth: 10 })
        _rawBody(resolveReferences: { maxDepth: 10 })
        _rawTopics(resolveReferences: { maxDepth: 10 })
        slug {
          current
        }
        main_image {
          asset {
            gatsbyImageData(width: 1440)
          }
        }
      }
    }
  }
`;
